<template>
    <div class="view pa24">
      <el-page-header @back="goBack" content="退货记录详情">
      </el-page-header>
      <commonTable
        :tableData="tableData"
        :total="total"
        :currentPage="pageNum"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
        <template v-slot:table>
          <el-table-column
              prop="className"
              align="center"
              label="产品类别"
          />
          <el-table-column
            prop="handle"
            align="center"
            label="标识"
            width="360"
          >
            <template slot-scope="scope">
              {{scope.row.handle}}
              <span :class="scope.row.type == 3?'name_status1':'name_status'">{{scope.row.type == 3 ? '箱' : '盒'}}</span>
              <span class="copyTxt" @click="copy(scope.row.handle)"><img src="../../assets/images/fz.png" /></span>
            </template>
          </el-table-column>
          <el-table-column
            prop="code"
            align="center"
            :label="labelName"
          >
          <template slot-scope="scope">
            {{scope.row.code||scope.row.dealerCode}}
          </template>
          </el-table-column>

          <el-table-column
              prop="nickName"
              align="center"
              label="操作人"
          >
          <template>
            {{name}}
          </template>
         </el-table-column>
          <el-table-column
            prop="createTime"
            align="center"
            label="退货时间"
          >
            <template>
                {{createTime}}
            </template>
          </el-table-column>
          <el-table-column align="center" width="200" label="操作">
            <template slot-scope="scope">
              <el-button  type="text" @click="detail(scope.row)">溯源</el-button>
            </template>
          </el-table-column>
        </template>
      </commonTable>
  <detail ref="boxDetail"></detail>
      <page-timeline ref="timeline"/>
    </div>
  </template>

  <script>
  import commonTable from "@/components/common/commonTable";
  import { warehouseLogPage,warehouseLogDetail, fetchCrkBatchList, fetchCrkBackDetail } from '@/api/warehouseManage.js'
  import detail from "./detail.vue";
  import PageTimeline from "@/pages/dashboard/components/detailDialog/PageTimeline.vue";
  export default {
    name: "dealerList",
    components: {
      commonTable,detail,PageTimeline
    },
    data() {
      return {
          tableData: [],
          pageSize: 10,
          pageNum: 1,
          total: 0,
        wareStatus: 1,
        labelName: '',
        name: '',
      };
    },
    created() {
      this.getList()
      this.labelName = this.$route.query.labelName
      this.name = this.$route.query.name
      this.createTime = this.$route.query.createTime
    },
    methods: {
      detail(row){
        this.$refs.timeline.show(row.pageHandleId)
      },
      goBack() {
        this.$router.push({path:'/crk/backLog'})
      },
        copy(context) {
                    // 创建输入框元素
                    let oInput = document.createElement('input');
                    // 将想要复制的值
                    oInput.value = context;
                    // 页面底部追加输入框
                    document.body.appendChild(oInput);
                    // 选中输入框
                    oInput.select();
                    // 执行浏览器复制命令
                    document.execCommand('Copy');
                    // 弹出复制成功信息
                    this.$message.success('复制成功');
                    // 复制后移除输入框
                    oInput.remove();
                },
        handleSizeChange(num) {
          this.pageSize = num;
          this.pageNum = 1;
          this.getList();
        },
        handleCurrentChange(num) {
          this.pageNum = num;
          this.getList();
        },
        handleAddDealer(type, id) {
            this.$refs.addDealerDialog.openDialog(type, id)
        },
        getList() {
          let params={
            pageNum: this.pageNum,
            pageSize: 10,
            // wareStatus:this.wareStatus,
            batchId: this.$route.query.id
          }
          fetchCrkBackDetail(params).then(res => {
            this.tableData = res.data.list
            this.total = res.data.total
          })
          // warehouseLogPage(params).then(res => {
          //   this.tableData = res.data.list
          //   this.total = res.data.total
          // })
        },
      boxDetail(data){
        console.log('data-------', data)
        this.$refs.boxDetail.init(data.id,this.wareStatus)
        this.$refs.boxDetail.dialogTableVisible= true
      }
    }
  }
  </script>

  <style lang="scss" scoped>
      .name_status {
          display: inline-block;
          width: 26px;
          height: 17px;
          line-height: 15px;
          background: rgba(63, 114, 246, 0.11);
          border: 1px solid #3F72F6;
          border-radius: 4px;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #3F72F6;
          text-align: center;
      }

      .name_status1 {
          display: inline-block;
          width: 26px;
          height: 17px;
          line-height: 15px;
          background: rgba(243, 136, 46, 0.11);
          border: 1px solid #F3882E;
          border-radius: 4px;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #F3882E;
          text-align: center;
      }

      .copyTxt {
          display: inline-block;
          margin-left: 5px;
          cursor: pointer;
          color: #3F72F6;
      }
  </style>

